import { render, staticRenderFns } from "./VideoProduct.vue?vue&type=template&id=0fb8aef1&scoped=true"
import script from "./VideoProduct.vue?vue&type=script&lang=js"
export * from "./VideoProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb8aef1",
  null
  
)

component.options.__file = "VideoProduct.vue"
export default component.exports